/** 
  1. Account
  2. 
*/
/* Account */
.btn-facebook,
.btn-google {
  padding: 0.5rem 0.75rem !important;
  .icon {
    width: 34px;
    height: 34px;
    line-height: 34px;
    border-radius: 0.25rem;
  }
}
.otp-container{
  .form-control{
    width: 2.5rem !important;
    height: 2.5rem !important;
  }
}