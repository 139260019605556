/** 
  1. Layout
  2. Modules
    2.1. Recipient Login
    2.2. Profile
    2.3. List View
    2.4. Donation
  3. Responsive
*/

/* Layout */
.public-layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  .mini-header {
    .social-media-icons {
      font-size: 1.125rem;
      line-height: 0.5;
    }
  }
  .header {
    top: 42px;
    padding: 0.5rem 1rem;
    img {
      width: 150px;
    }
  }
  .wrapper {
    min-height: calc(100% - 125px) !important;
    padding: 125px 0.75rem 1rem 0.75rem;
    display: flex;
    align-items: center;
    flex-grow: 1;
    // > * {
    //   flex-grow: 1;
    // }
  }
  .footer {
    img {
      max-width: 100px;
    }
  }
}
.page-title {
  .tag-line {
    letter-spacing: 1.5px;
  }
  .title-divider {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 32px;
    background: url("../images/title-divider.png");
    background-repeat: no-repeat;
    background-size: 100% 12px;
  }
}

/* Modules */

/* Recipient Login */
.recipient-login {
  .btn {
    height: 56.5px;
  }
}

/* Profile */
.grid-list {
  margin: 3.5rem 0 2rem 0 !important;
}
.validator {
  .profile-pic {
    position: absolute;
    top: -65px;
    left: 50%;
    margin-left: -65px;
    max-width: 130px;
  }
}
.need {
  .profile-pic {
    position: absolute;
    top: -65px;
    left: 50%;
    margin-left: -65px;
    max-width: 130px;
  }
  h5 {
    font-size: 1.125rem;
    line-height: 1.25;
  }
}
.swiper-slide {
  .need,
  .validator {
    width: 300px;
  }
}
.public-layout {
  .fundraising-profile {
    .recipient-info {
      position: sticky;
      top: 275px;
      img {
        max-width: 220px;
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .card-body {
        padding: 125px 0 0;
      }
    }
  }
}
.organization-profile,
.validator-profile,
.user-giving-page {
  width: 100%;
  h1 {
    font-size: calc(1.34375rem + 0.8vw);
  }
  .profile-picture {
    width: 335px;
    height: 325px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .square {
    position: absolute;
    z-index: 1;
    top: 30px;
    left: -15px;
  }
  .dot {
    position: absolute;
    top: -30px;
    left: -35px;
    z-index: 0;
  }
  .content {
    p {
      line-height: 1.65;
    }
  }
}

/* List View */
.validator-list-view {
  h1 {
    font-size: calc(1.34375rem + 0.8vw);
  }
}
.organization-list-view {
  h1 {
    font-size: calc(1.34375rem + 0.8vw);
  }
  .organization.card {
    .profile-pic {
      position: absolute;
      top: -65px;
      left: 50%;
      margin-left: -65px;
      max-width: 130px;
    }
  }
}

/* Donation */
.checkout {
  .customDonationInputGroup {
    max-width: 120px;
    .form-control {
      line-height: 1.7;
    }
  }
  #txtCustomTip {
    font-size: 0.938rem;
  }
  .helpText {
    max-width: 480px;
  }
}

.receipt {
  max-width: 600px;
  .bg {
    background: url("../../resources/images/bg-receipt.png");
    width: 100%;
    height: 180px;
    display: table;
    background-size: cover;
    .logo {
      img {
        width: 100px;
        height: 100px;
        margin-top: 25px;
        border: 6px solid;
        padding: 2px;
      }
    }
  }
}

/* Responsive */
@media (max-width: 1200px) {
  .header {
    .navbar-collapse {
      box-shadow: var(--benevolent-box-shadow);
    }
  }
}
@media (min-width: 1400px) {
  .header {
    .navbar-collapse {
      .nav-link {
        padding: 0.875rem !important;
      }
    }
  }
}
