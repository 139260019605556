/** 
  1. Layout
  2. Vendor Component Customization
  3. Modules - Portal
    3.1. Dashboard
    3.2. Email Template 
    3.3. Need Creation
    3.4. Need Updates
    3.5. Manage Needs
    3.6. Search Needs
    3.7. Featured Needs
    3.7. Profile
    3.8. Donut Chart
    3.9. Transactions
    3.10.Reallocation
    3.11.User Giving Page
  4. Modules - WordPress
*/

/* Layout */
.private-layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  .menu-toggle {
    width: 28px;
    height: 28px;
    padding: 0 !important;
    span {
      position: relative;
      display: block;
      width: 100%;
    }
    span,
    span:after,
    span:before {
      height: 1.75px;
      transition: all 0.3s;
    }
    span:after,
    span:before {
      content: "";
      position: absolute;
      left: 0;
      width: 70%;
    }
    span:before {
      top: -9px;
    }
    span:after {
      top: 9px;
    }
    &.open span {
      &:before {
        transform: rotate(45deg) translate(5px, 5px);
        top: -6px;
      }
      &:after {
        transform: rotate(-45deg) translate(7px, -8px);
        top: 11px;
      }
    }
  }
  .push-menu {
    position: fixed;
    top: 0;
    bottom: 0;
    overflow-y: auto;
    width: 200px;
    transition: transform 0.3s ease;
    transform: translateX(-100%);
    .nav-link {
      padding: 0.75rem 1rem;
    }
    &.open {
      transform: translateX(0);
    }
  }
  .wrapper {
    min-height: calc(100% - 85px) !important;
    transition: transform 0.3s ease;
    overflow-x: hidden;
    padding: 90px 0.75rem 1rem 0.75rem;
    margin: 0 !important;
    &.pushed {
      transform: translateX(200px);
      width: calc(100% - 200px) !important;
    }
    // > * {
    //   flex-grow: 1;
    // }
  }
  .header,
  .footer {
    transition: transform 0.3s ease;
    //overflow-x: hidden;
    &.pushed {
      transform: translateX(200px);
      width: calc(100% - 200px) !important;
    }
  }
  .notification {
    top: 75px;
  }
}
.export {
  .dropdown-toggle:after {
    display: none;
  }
  .count {
    position: absolute;
    width: 22px;
    height: 22px;
    text-align: center;
    line-height: 24px;
    border-radius: 20px;
    right: 0;
    top: 0;
  }
  .dropdown-menu {
    left: -110px !important;
    width: 265px !important;
    max-height: 300px !important;
    overflow-y: auto;
    ul {
      .progress {
        border-radius: 0;
      }
    }
  }
}
.more-menu {
  max-width: -moz-fit-content;
  max-width: fit-content;
  position: inherit !important;

  .dropdown-toggle:after {
    content: none;
  }
}
.modal-crop {
  .crop-container {
    width: 400px;
    height: 400px;
  }
}
.avatar {
  max-width: 48px !important;
}
.cart-quantity {
  width: 21px;
  height: 21px;
  line-height: 23px;
  font-size: 0.75rem;
}

/* Vendor Component Customization */
.react-confirm-alert-overlay {
  z-index: 1030;
}
.react-select-container {
  .react-select__control {
    min-height: 58px !important;
    font-size: 0.815rem;
  }
  .react-select__menu {
    font-size: 0.815rem;
  }
}
.swiper-slide {
  width: auto;
  height: auto;
}
.swiper-slide-grid {
  margin: 4.025rem 0 2rem 0;
}
.swiper-button-next,
.swiper-button-prev {
  display: inline-block;
  width: 46px;
  height: 46px;
  line-height: 42px;
  text-align: center;
  border: 2px solid;
  border-radius: 50%;
  cursor: pointer;
}
.react-datepicker__input-container {
  width: 105% !important;
}
.react-datepicker__navigation {
  top: 15px !important;
}
.react-datepicker__header {
  padding: 15px 0 !important;
}
.react-datepicker__close-icon::after {
  font-size: 1.125rem !important;
  line-height: 0 !important;
  font-weight: 100 !important;
}
.date-range-picker {
  width: 160px !important;
  .react-datepicker-wrapper {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
  }
}
.PhoneInputInput {
  border: 0;
  outline: 0;
}

/* Modules - Portal */

/* Dashboard */
.dashboard {
  .icon {
    width: 52px;
    height: 52px;
    text-align: center;
    line-height: 50px;
    border-radius: 8px;
  }
  .needs-requiring-attention,
  .recent-submissions {
    .profile-pic {
      max-width: 90px;
    }
    h5 {
      font-size: 1rem;
      line-height: 1.4;
    }
  }
  .org-summary-report {
    .avatar {
      max-width: 24px !important;
    }
    .custom-select {
      min-width: 200px;
      max-width: 250px;
    }
  }
}

/* Email Template */
.data-tags {
  h6 {
    top: -1rem;
  }
  .table > :not(caption) > * > * {
    padding: 0.325rem 0.5rem 0.325rem 0;
  }
}

/* Need Creation */
.create-need {
  flex-grow: 1;
}
.story-carousel {
  .carousel-caption {
    position: static !important;
    max-height: 200px;
    overflow: auto;
    text-align: start !important;
    padding: 0 !important;
  }
  .card-footer {
    display: flex;
    justify-content: space-between;
    margin: 0 1rem;
    padding: 0.125rem 0;
  }
}
.recipient-invitation {
  .guidance-message {
    line-height: 1.75;
  }
  .box {
    background-image: url("../images/bg-invitation.png");
    background-size: cover;
    background-position: bottom center;
  }
}
.recipient-profile-avatar {
  .file-upload,
  .custom-avatar {
    width: 130px;
    height: 130px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px dashed;
    border-radius: 0.75rem;
    font-size: 0.8rem;
  }
  .preview {
    max-width: 130px !important;
    height: 130px;
    border: 1px dashed;
    padding: 0;
    border-radius: 0.75rem;
  }
  .btn-close {
    position: absolute;
    left: 105px;
    top: 8px;
  }
}
.avatars-container {
  max-height: 300px;
  overflow-y: auto;
}

/* Need Updates*/
.need-updates {
  .attachments {
    max-height: 250px;
    overflow: auto;
  }
  .preview {
    width: 120px;
    height: 120px;
    object-fit: cover;
  }
}

/* Manage Needs */
.modal-share {
  width: 475px;
  .icon {
    display: inline-block;
    text-decoration: none;
    width: 50px;
    height: 50px;
    line-height: 50px;
    border: 1px solid;
    text-align: center;
    border-radius: 50%;
    margin-right: 1rem;
  }
  .btn-copy {
    height: 56.5px;
  }
}

/* Search Needs */
.search-needs {
  .page-title {
    .title-divider {
      top: 28px;
      background-size: 100% 8px;
    }
  }
  .navbar-toggler:focus {
    box-shadow: none;
  }
  #filters {
    .accordion-button {
      padding: 1rem 0;
    }
    .accordion-button::after {
      content: "+";
      font-size: 1.25rem;
      line-height: 1.5rem;
      background-image: none;
      font-weight: 600;
    }
    .accordion-button:not(.collapsed)::after {
      content: "-";
      font-size: 1.75rem;
      line-height: 1.15rem;
      background-image: none;
      font-weight: 600;
    }
    .categories,
    .locations {
      max-height: 148px;
      overflow-y: auto;
    }
  }
}

/* Featured Needs */
.featured-needs {
  img {
    max-width: 80px;
  }
  .need-title {
    font-size: 1rem;
  }
}

/* Profile */
.fundraising-profile {
  .avatar {
    max-width: 75px !important;
  }
  .recipient-info {
    border-radius: 1rem;
    margin: 110px 0 0;
    img {
      max-width: 220px;
      margin: -110px 0 0;
    }
    .divider-sm {
      max-width: 70px;
    }
    .divider {
      max-width: 300px;
    }
    .analytics {
      max-width: 300px;
      margin: auto;
      .box {
        width: 150px;
        display: inline-flex;
        align-items: center;
        text-align: start;
        .data {
          font-size: 1rem;
        }
      }
    }
  }
  .donation-info {
    position: sticky;
    top: 140px;
  }
  .recent-donation {
    width: 3rem;
    height: 3rem;
    line-height: 3rem;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    font-size: 0.815rem;
    font-weight: 600;
    text-decoration: none;
    margin: 0 0.5rem 0.5rem 0;
  }
  #needUpdates {
    h6 {
      font-size: 1rem;
    }
    .attachment-preview {
      max-height: 150px;
    }
  }
}
.organization-private-profile {
  .basic-info {
    margin: 75px 0 0;
    border-radius: 1rem;
    img {
      max-width: 150px;
      margin: -75px auto 0;
    }
  }
  .biography {
    line-height: 1.6;
  }
}
.user-profile {
  .basic-info {
    margin: -12px 0 90px;
    img {
      max-width: 150px;
      left: 50%;
      top: 50%;
      margin: 25px 0 0 -65px;
    }
  }
  .biography {
    line-height: 1.6;
  }
}

/* Donut Chart */
.fundraising-progress {
  position: relative;
  max-width: 225px;
  canvas {
    position: relative;
    z-index: 1;
  }
  .needed {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #aec9d0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 0;
  }
  .lblValue.lg {
    font-size: 1rem;
  }
  .lblTitle.lg {
    font-size: 0.815rem;
  }
  .lblValue.sm {
    font-size: 0.815rem;
  }
  .lblTitle.sm {
    font-size: 0.75rem;
  }
}
.chart-legend {
  span {
    width: 12px;
    height: 12px;
    margin: 0 0.325rem;
    border-radius: 0.25rem;
  }
}

/* Transactions */
.modal-receipt {
  .modal-dialog {
    max-width: 650px;
  }
}
.hasTribute {
  width: 28px;
  height: 28px;
  line-height: 28px;
}

/* Reallocation */
.reallocation {
  .fundraising-progress {
    max-width: 110px;
  }
  .box {
    margin: 0 0 1rem 0;
    padding: 1rem;
    border-left: 5px solid;
    border-radius: 1rem;
  }
  .tag {
    display: inline-block;
    padding: 0.35rem 0.5rem;
    margin: 0 0.35rem 0.35rem 0;
    font-size: 0.75rem;
  }
  .btn-close {
    font-size: 0.65rem;
  }
  .priority-needs {
    .scroll {
      max-height: calc(100vh - 275px);
    }
  }
}

/* User Giving Page */
.need.list-group {
  height: 620px;
  overflow-y: scroll;
  .btn.add {
    padding: 0.125rem 0.305rem;
    line-height: 1;
  }
  .btn.remove {
    padding: 0.125rem 0.375rem;
    line-height: 1;
  }
}

/* Modules - WordPress */
.explore-active-needs-container {
  .nav-item {
    padding: 1rem 0;
    h4 {
      font-size: 1.125rem;
    }
    .nav-link {
      border-bottom: 0;
    }
    &:not(:last-child) .nav-link {
      border-right: 1px solid;
    }
    &:has(.nav-link.active) {
      border-bottom: 3px solid;
    }
    &:first-child {
      border-top-left-radius: 1.5rem;
    }
    &:last-child {
      border-top-right-radius: 1.5rem;
    }
  }
  .tab-content {
    padding: 1.5rem;
  }
}
.explore-categories {
  .swiper-container {
    height: 150px;
  }
  .swiper-slide {
    .box {
      width: 125px;
      height: 115px;
    }
  }
}
.explore-needs-chosen {
  height: 22px;
  overflow: hidden;
}
.explore-insights {
  .card {
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.12);
  }
}
