/** 
  1. General
  2. Bootstrap Customization
*/
/* General */
html,
body,
#root {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.logo {
  max-width: 220px;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  background-color: #ffffff;
}
.scroll::-webkit-scrollbar {
  width: 6px;
  background-color: #ffffff;
}
.scroll::-webkit-scrollbar-thumb {
  border-radius: 6px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #f0f0f0;
}
.title {
  font-family: "Abhaya Libre", serif;
  font-weight: 800;
}
.module-title {
  font-size: 1rem;
}
.fs-small {
  font-size: 0.815rem;
}
.notification {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  transition: transform 0.3s ease-in-out;
  transform: translateY(100%);
  z-index: 1051;
  .alert {
    margin: 0;
    border-radius: 0;
    padding: 1.5rem;
  }
}
.notification.show {
  transform: translateY(0%);
}
.notification.migration-alert {
  top: 0;
  bottom: auto;
}
.btn-md {
  padding: 0.5rem 1.75rem;
}
.btn-arrow {
  border-radius: 1rem 0 1rem 0 !important;
}
.unauthorized,
.internal-server-error,
.access-denied {
  letter-spacing: 0.5px;
}
textarea {
  height: 100px !important;
}
.full-width-divider {
  margin: 0 -1.5rem;
}
.tailored-height-menu {
  .dropdown-menu {
    max-height: 300px !important;
    overflow-y: auto;
  }
}
.text-pre-line {
  white-space: pre-line;
}

/* Bootstrap Customization */
.btn-link {
  text-decoration: none;
}
.form-floating {
  label {
    z-index: 0;
  }
}
.input-group {
  .form-floating {
    .form-control {
      border-right: 0;
    }
    label {
      padding: 1.125rem 0.75rem;
    }
  }
}
.dropdown-menu {
  &.show {
    -webkit-box-shadow: 0 5px 10px rgba(30, 32, 37, 0.12);
    box-shadow: 0 5px 10px rgba(30, 32, 37, 0.12);
    border: 0;
    top: 115%;
  }
  .dropdown-item {
    font-size: 0.815rem;
  }
}
.form-switch {
  .form-check-input {
    height: 1.15em;
  }
}
.table > :not(caption) > * > * {
  padding: 1rem 0.5rem;
  vertical-align: middle;
}
th {
  font-weight: 600;
}
.pagination {
  justify-content: end !important;
  margin: 1rem 0 0;
  .page-item {
    margin: 2px;
  }
}
.nav-tabs {
  .nav-link {
    border-width: 0 0 2px 0;
  }
}
.modal-sm {
  max-width: 350px;
}
.progress {
  height: 6px;
}
